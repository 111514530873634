export const role_management_constants = {
  resource: [
    {
      name: "Card Management",
      permissions: {
        create: 0,
        view: 0,
        update: 0,
        delete: 0,
      },
    },
    {
      name: "Logs Management",
      permissions: {
        create: 0,
        view: 1,
        update: 0,
        delete: 0,
      },
    },
    {
      name: "Default Dashboard",
      permissions: {
        create: 0,
        view: 1,
        update: 0,
        delete: 0,
      },
    },

    {
      name: "Alert Management",
      permissions: {
        create: 0,
        view: 0,
        update: 0,
        delete: 0,
      },
    },
    {
      name: "Group Management",
      permissions: {
        create: 0,
        view: 0,
        update: 0,
        delete: 0,
      },
    },
    {
      name: "Panel Management",
      permissions: {
        create: 0,
        view: 0,
        update: 0,
        delete: 0,
      },
    },
    {
      name: "Report Management",
      permissions: {
        create: 0,
        view: 0,
        update: 0,
        delete: 0,
      },
    },
    {
      name: "Dashboard Management",
      permissions: {
        create: 0,
        view: 0,
        update: 0,
        delete: 0,
      },
    },
    {
      name: "CDR Search",
      permissions: {
        create: 0,
        view: 0,
        update: 0,
        delete: 0,
        download: 0,
      },
    },
  ],
  initial_values: {
    key: 0,
    name: "",
    dashboardCount: 0,
    panelCount: 0,
    staticReports: [],
    // cardsList: [],
    dynamicDashboard: [],
    resources: {
      //"User Management": [],
      "Report Management": [],
      "Card Management": [],
      //"Role Management": [],
      "Panel Management": [],
      "Dashboard Management": [],
      "Logs Management": ["view"],
      "Alert Management": [],
      "Group Management": [],
      "Default Dashboard": ["view"],
      "CDR Search": [],
    },
  },
};

export const TIME_ZONE = "Asia/Kolkata";
