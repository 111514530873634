//import { t } from "i18next";

export const convertToDateObject = (dateString) => {
  let date, month, year;
  if (dateString) {
    let newFormat = dateString.split("-");
    if (newFormat.length === 2) {
      month = newFormat[0];
      year = newFormat[1];
    } else if (newFormat.length === 3) {
      date = newFormat[0];
      month = newFormat[1];
      year = newFormat[2];
    }
    return new Date(year, month - 1);
  }
};

/////////////////////Styles///////////////////////
export const templates = {
  candidate: "Candidate",
  recruiter: "Recruiter",
  platformAdmin: "Platform Admin",
};
////////////////////////////////////////////////////

export const commonRegex = /^[^<>+$;'"\\/~|:]+$/;
export const specialRegex = /^[^<>$\\~|]+$/;
export const passwordRegex = /[!@^*+()_=\\,.?\\-]+/;
export const passwordRestrictedRegex = /^[^<>&;$#%"'`/:|[\]{}]+$/;
export const onlySpaceRegex = /(?=.*[a-z])|(?=.*[A-Z])/;
export const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
export const linkedinRegex =
  /https:\/\/(?:[a-z]+\.)?linkedin\.com\/[a-zA-Z0-9-]+/;
export const websiteRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const alphaAndSpaceRegex = /^[A-Za-z][A-Za-z ]*$/;
export const onlyNumericsRegex = /^\d+$/; // only numbers are allowed

export const onlyNumSpaceNotAllowed =
  /^(?=.*[a-zA-Z])[a-zA-Z0-9_\s!@#$%^&*()\-+=~"`'<>,./?;:{}[\]|\\]*$/; //Only space,only special char,only numerics not allowed.Alphabets with space,numbers and special chars allowed.

//export const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
export const passwordAllowedCharacters = " !@^*+()_=\\,.?\\-";
export const onlyZerosNotAllowedRegex = /^(?!0+$)[0-9]*$/;
export const onlyZerosRegex = /^(?!0+$)[0-9A-Za-z]/;
export const invalidPINRegex =
  /^(?![-\s])(?![A-Za-z]+$)(?=.*[0-9])[0-9A-Za-z\s-]*$/;

export const panelOptions = [
  { value: "line", label: "Line" },
  { value: "bar", label: "Bar" },
  { value: "table", label: "Table" },
  { value: "multi", label: "Multi-axis" },
  { value: "pie", label: "Pie" },
];

export const recordsPanel = [];
for (let i = 5; i <= 50; i += 5) {
  recordsPanel.push({ value: i.toString(), label: i.toString() });
}

export const logicalOperator = [
  { value: "and", label: "AND" },
  { value: "or", label: "OR" },
  { value: "no", label: "NO" },
];

export const alertTypeOptions = [
  { label: "Delivery Drop", value: "Delivery Drop" },
  { label: "Error", value: "Error" },
  { label: "Volume Drop Or Spike", value: "Volume Drop Or Spike" },
  { label: "Delivery Report Pending", value: "Delivery Report Pending" },
];

export const filterOptions = [
  { label: "Supplier", value: "Supplier" },
  { label: "Customer", value: "Customer" },
  { label: "Destination", value: "Destination" },
  { label: "Supplier-Destination", value: "Supplier-Destination" },
  { label: "Customer-Destination", value: "Customer-Destination" },
  {
    label: "Customer-Supplier-Destination",
    value: "Customer-Supplier-Destination",
  },
];

export const vloumeDropFilterOptions = [
  { label: "Customer", value: "Customer" },
  { label: "Destination", value: "Destination" },

  { label: "Customer-Destination", value: "Customer-Destination" },
];

export const deliveryReportDropFilterOptions = [
  { label: "Customer", value: "Customer" },

  { label: "Customer-Destination", value: "Customer-Destination" },
  { label: "Supplier", value: "Supplier" },
  { label: "Supplier-Destination", value: "Supplier-Destination" },
];

export const timePeriodOptions = [
  { label: "Yesterday", value: "Yesterday" },

  { label: "Last three days", value: "Last three days" },
  { label: "Last seven days", value: "Last seven days" },
  { label: "Last weekdays", value: "Last weekdays" },
];

export const timeIntervalOptions = [
  { label: "15 min", value: "15 min" },

  { label: "30 min", value: "30 min" },
  { label: "45 min", value: "45 min" },
  { label: "1 hour", value: "1 hour" },
];

export const timeAcessOptions = {
  minute: ["Last Hour", "Calendar"],
  hour: [
    "Last Hour",
    "Today",
    "Last 6 Hours",
    "Last 12 Hours",
    "Last 24 Hours",
    "Calendar",
  ],
  day: ["Last Seven Days", "Last Week", "Today", "Calendar"],
  week: ["Last Seven Days", "Last Week", "Calendar"],
  month: ["Last 30 Days", "This Month", "Last Month", "Calendar"],
};

export const defaultTimeRange = [
  "Last Hour",
  "Today",
  "Last 6 Hours",
  "Last Seven Days",
  "Last 12 Hours",
  "Last Week",
  "Last 24 Hours",
  "Last 30 Days",
  "This Month",
  "Last Month",
  "Calendar",
];

export const protocolTypeOptions = [
  { label: "All", value: "All" },
  { label: "A2P", value: "A2P" },
  { label: "P2P", value: "P2P" },
];

export const interfaceTypeOptions = [
  { label: "All", value: "All" },
  { label: "SMPP", value: "SMPP" },
  { label: "SS7", value: "SS7" },
];

export const ConditionDisplay = ({ conditions }) => {
  return (
    <>
      {conditions?.map((conditionGroup, index) => {
        let conditionString;

        if ("type1" in conditionGroup) {
          conditionString = Object.entries(conditionGroup)
            .map(([key, value]) => {
              if (key.startsWith("type")) {
                return value;
              } else {
                return `{${value}}`;
              }
            })
            .filter(Boolean)
            .join(" ");
        } else {
          const {
            field,
            condition: conditionType,
            value,
            operator,
          } = conditionGroup;

          conditionString = [
            field,
            conditionType,
            value,
            operator && `{${operator}}`,
          ]
            .filter(Boolean)
            .join(" ");
        }

        if (conditionGroup.type4) {
          conditionString = conditionString.replace(
            conditionGroup.type4,
            `{${conditionGroup.type4}}`
          );
        }

        if (index === conditions.length - 1 && conditionGroup.type4) {
          conditionString = conditionString.replace(
            `{${conditionGroup.type4}}`,
            ""
          );
        }
        if (index === conditions.length - 1 && conditionGroup.operator) {
          conditionString = conditionString.replace(
            `{${conditionGroup.operator}}`,
            ""
          );
        }

        const isEmpty = Object.values(conditionGroup).every(
          (value) => value === ""
        );

        return (
          <div key={index} className="mb-1">
            <span className={`rounded-md p-1 text-xs ml-1`}>
              {/* {console.log("formData.conditions", conditions)} */}
              {isEmpty ? "No Filters added" : conditionString}
            </span>
          </div>
        );
      })}
    </>
  );
};
export const customerInterface = [
  { value: "SMPP", label: "SMPP" },
  { value: "SS7", label: "SS7" },
  { value: "SMPP_ES", label: "SMPP_ES" },
];
export const customerProtocol = [
  { value: "A2P", label: "A2P" },
  { value: "P2P", label: "P2P" },
];
export const destinationMsg = [
  { value: "AT", label: "AT" },
  { value: "MT", label: "MT" },
  { value: "IN", label: "IN" },
  { value: "CF", label: "CF" },
];
export const trafficType = [
  { value: "A2P", label: "A2P" },
  { value: "P2P", label: "P2P" },
];
export const supplierProtocol = [
  { value: "A2P", label: "A2P" },
  { value: "P2P", label: "P2P" },
  { value: "NA", label: "NA" },
];

export const transactionType = [
  { value: "Select All", label: "Select All" },
  { value: "AO-AT", label: "AO-AT" },
  { value: "AO-MT", label: "AO-MT" },
  { value: "MT-IN", label: "MT-IN" },
  { value: "MT-CF", label: "MT-CF" },
  { value: "RI-IN", label: "RI-IN" },
  { value: "RI-CF", label: "RI-CF" },
  { value: "DR-AT", label: "DR-AT" },
  { value: "DR-MT", label: "DR-MT" },
];
