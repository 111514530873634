import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import ForwardRoute from "./components/Routes/ForwardRoute";
import "react-simple-toasts/dist/theme/dark.css";

const baseName = process.env.REACT_APP_BASE_NAME;

function App() {
  const { i18n } = useTranslation();
  const val = i18n.resolvedLanguage;

  useEffect(() => {
    if (val === "ar") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }

    const handleRightClick = (event) => {
      event.preventDefault();
    };

    const handleKeyDown = (event) => {
      if (event.key === "F12" || (event.key === "I" && event.ctrlKey)) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleRightClick);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleRightClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [val, i18n]);

  return (
    <Router basename={baseName}>
      <Routes>
        <Route path="/auth/*" element={<ForwardRoute />} />
        <Route path="/app/*" element={<ProtectedRoute />} />
        <Route path="*" element={<Navigate to="/auth" />} />
      </Routes>
    </Router>
  );
}

export default App;
