import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,

  // useContext,
} from "react";
import moment from "moment";
import axios from "axios";
import { config } from "../assets/config/config";
import ThemedSuspense from "../components/ThemedSuspense";
import { TIME_ZONE } from "../utils/constants";

const apiUrl = config.api.url;

// create context
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [fetchRole, setFetchRole] = useState(false);
  const [reset, setReset] = useState(false);
  const [configApiData, setConfigApiData] = useState();
  const [storeLoginTime, setStoreLoginTime] = useState();
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const fetchAfterLogin = (token, roleId) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };

    try {
      axios.get(`${apiUrl}/v1/users/config`, config).then(({ data }) => {
        // console.log("result", data);
        setConfigApiData(data);
      });
      return axios
        .get(`${apiUrl}/v1/roles/${roleId}`, config)
        .then(({ data }) => {
          setRoles(data);
          setFetchRole(false);
          setLoaded(true);
        });
    } catch (error) {
      console.error(error);
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (fetchRole === true && user?.roleId && accessToken) {
      fetchAfterLogin(accessToken.token, user.roleId);
    }
  }, [fetchRole, user, accessToken]);

  const refreshTokens = useCallback(() => {
    return axios
      .post(`${apiUrl}/v1/auth/refresh-tokens`, {})
      .then(({ data }) => {
        setAccessToken(data.token);
        setUser(data.user);
        fetchAfterLogin(data.token.token, data.user.roleId);
        return data;
      })
      .catch((error) => {
        setLoaded(true);
        setUser(null);
        setAccessToken(null);
        setLoaded(true);
        return error;
      });
  }, []);

  const startSilentRefresh = useCallback(() => {
    if (accessToken) {
      const tokenExpires = moment(accessToken.expires);
      const tokenMaxAge = tokenExpires.diff(moment().add(1, "minutes"));
      setTimeout(() => {
        refreshTokens();
      }, tokenMaxAge);
    }
  }, [accessToken, refreshTokens]);

  const syncLogout = (event) => {
    if (event.key === "logout") {
      setAccessToken(null);
      setUser(null);
    }
  };
  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(
      (config) => {
        config.withCredentials = true;
        config.credentials = "include";
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(interceptorId);
    };
  }, [accessToken]);

  // useEffect(() => {
  //   axios.interceptors.response.use(
  //     (response) => response,
  //     async (error) => {
  //       const config = error?.config;
  //       if (error?.response?.status === 401 && !config?.sent) {
  //         config.sent = true;
  //         //  const result = await memoizedRefreshToken();
  //         // if (result?.access_token) {
  //         //   config.headers = {
  //         //     ...config.headers,
  //         //     authorization: `Bearer ${result?.access_token}`,
  //         //   };
  //         // }
  //         setAccessToken(null);
  //         setUser(null);
  //         setLoaded(true);
  //         window.localStorage.setItem("logout", moment());
  //         // axios
  //         //   .post(`${apiUrl}/v1/auth/logout`, {})
  //         //   .then((response) => {
  //         //     window.localStorage.setItem("logout", moment());
  //         //   })
  //         //   .catch((err) => {});
  //         return axios(config);
  //       }
  //       // if (error?.response?.status === 500 || error?.response?.status === 502) {
  //       //   return Promise.reject({
  //       //     response: {
  //       //       data: { message: t("_Something_went_wrong._Please_try_again") },
  //       //     },
  //       //   });
  //       // }
  //       return Promise.reject(error);
  //     }
  //   );
  // }, []);

  useEffect(() => {
    refreshTokens();
  }, [refreshTokens]);

  useEffect(() => {
    startSilentRefresh();
  }, [accessToken, startSilentRefresh]);

  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return function cleanup() {
      window.removeEventListener("storage", syncLogout);
    };
  }, []);

  const handleLoginTime = () => {
    const storedLoginTime = window.localStorage.getItem("loginTime");
    if (storedLoginTime) {
      const loginTime = new Date(parseInt(storedLoginTime));
      setStoreLoginTime(formatDateTime(loginTime));
    } else {
      setStoreLoginTime("---");
    }
  };

  const formatDateTime = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    handleLoginTime(); // Call handleLoginTime when component mounts or refreshes
  }, []);

  const value = useMemo(() => {
    const register = (username, email, password) => {
      return axios
        .post(`${apiUrl}/v1/auth/register`, {
          name: username,
          email: email,
          password: password,
        })
        .then((response) => {
          setAccessToken(response.data.token);
          setUser(response.data.user);
          startSilentRefresh();
        });
    };

    const login = (email, password, callback = false) => {
      return axios
        .post(`${apiUrl}/v1/auth/login`, {
          email: email,
          password: password,
          timezone: TIME_ZONE,
        })
        .then((response) => {
          const loginTime = new Date();
          window.localStorage.setItem("loginTime", loginTime.getTime());
          handleLoginTime();
          if (response.data.resetPassword === true) {
            setReset(true);
            setAccessToken(response.data.token);
            setUser(response.data.user);
            // startSilentRefresh()
            let config = {};
            config.headers = {};
            config.headers.Authorization = `Bearer ${response.data?.token?.token}`;
            return fetchAfterLogin(
              response.data.token.token,
              response.data.user.roleId
            );
          } else if (!response?.data?.otpSent) {
            setAccessToken(response.data.token);
            setUser(response.data.user);
            // startSilentRefresh()
            let config = {};
            config.headers = {};
            config.headers.Authorization = `Bearer ${response.data.token.token}`;

            return fetchAfterLogin(
              response.data.token.token,
              response.data.user.roleId
            );
          } else {
            callback && callback(response);
          }
        });
    };

    const logout = () => {
      setAccessToken(null);
      setUser(null);
      return axios
        .post(`${apiUrl}/v1/auth/logout`, {})
        .then((response) => {
          window.localStorage.setItem("logout", moment());
        })
        .catch((err) => {});
    };

    const otpVerificationCall = (email, OTP) => {
      return axios
        .post(`${apiUrl}/v1/auth/verify-otp`, {
          email: email,
          otp: OTP,
          timezone: timezone,
        })
        .then(({ data }) => {
          if (data.resetPassword === true) {
            setReset(true);
            setAccessToken(data.token);
            setUser(data.user);
            // startSilentRefresh()
            let config = {};
            config.headers = {};
            config.headers.Authorization = `Bearer ${data.token.token}`;
            return fetchAfterLogin(data.token.token, data.user.roleId);
          } else {
            setAccessToken(data.token);
            setUser(data.user);
            // startSilentRefresh()
            let config = {};
            config.headers = {};
            config.headers.Authorization = `Bearer ${data.token.token}`;

            return fetchAfterLogin(data.token.token, data.user.roleId);
          }
        });
    };

    const forgotPassword = (email) => {
      return axios.post(`${apiUrl}/v1/auth/forgot-password`, {
        email: email,
      });
    };

    const resetPassword = (password, resetToken) => {
      return axios.post(
        `${apiUrl}/v1/auth/reset-password?token=${resetToken}`,
        {
          password: password,
        }
      );
    };

    const verifyEmail = (emailVerificationToken) => {
      return axios.post(
        `${apiUrl}/v1/auth/verify-email?token=${emailVerificationToken}`,
        {}
      );
    };

    return {
      user,
      setUser,
      register,
      setRoles,
      roles,
      login,
      logout,
      forgotPassword,
      resetPassword,
      verifyEmail,
      otpVerificationCall,
      reset,
      configApiData,
      setConfigApiData,
      storeLoginTime,
      setStoreLoginTime,
      timezone,
      setTimezone,
    };
  }, [user, roles, startSilentRefresh, reset]);

  if (!isLoaded) {
    return <ThemedSuspense />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
